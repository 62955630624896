const imagesWagons = {
  Cisterne: [
    {
      id: 1,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710637/CIST/IMG_7546_ifiatg.jpg"
    },
    {
      id: 2,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710637/CIST/IMG_7567_zgy22u.jpg"
    },
    {
      id: 3,
      col: "4",

      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710637/CIST/IMG_7650_khe58v.jpg"
    },
    {
      id: 4,
      col: "4",

      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710636/CIST/IMG_7545_mkxwab.jpg"
    },
    {
      id: 5,
      col: "4",

      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710636/CIST/IMG_7544_vmgy9e.jpg"
    },
    {
      id: 6,
      col: "6",

      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710636/CIST/IMG_7535_nvptxz.jpg"
    },
    {
      id: 7,
      col: "6",

      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710636/CIST/IMG_7543_dtlqpr.jpg"
    },
    {
      id: 8,
      col: "3",

      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710635/CIST/IMG_7521_udxszj.jpg"
    },
    {
      id: 9,
      col: "3",

      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710635/CIST/IMG_7541_bbstnt.jpg"
    },
    {
      id: 10,
      col: "3",

      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710635/CIST/IMG_7502_ad9fgk.jpg"
    },
    {
      id: 11,
      col: "3",

      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710634/CIST/vagcisterna017_kdjy5e.jpg"
    },
    {
      id: 12,
      col: "6",

      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710634/CIST/vagcisterna013_a9llmi.jpg"
    },
    {
      id: 13,
      col: "6",

      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710633/CIST/vagcisterna014_h6coqv.jpg"
    },
    {
      id: 14,
      col: "3",

      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710633/CIST/vagcisterna006_jus4hx.jpg"
    },
    {
      id: 15,
      col: "3",

      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710633/CIST/vagcisterna012_ztihfi.jpg"
    },
    {
      id: 16,
      col: "3",

      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710633/CIST/vagcisterna007_wktfb7.jpg"
    },
    {
      id: 17,
      col: "3",

      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710633/CIST/vagcisterna003_xrobta.jpg"
    }
  ],
  HA: [
    {
      id: 1,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610952/HA/HA_0003_ha010-min_w4xtut.png"
    },
    {
      id: 2,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610952/HA/HA_0004_ha005-min_qubsvu.png"
    },
    {
      id: 3,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610952/HA/HA_0002_IMG_6143-min_wla7qh.png"
    },
    {
      id: 4,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610951/HA/HA_0006_DJI_0489.00_03_11_03.Still012-min_gcxfag.png"
    },
    {
      id: 5,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610950/HA/HA_0008_DJI_0489.00_01_59_13.Still008-min_r4plns.png"
    },
    {
      id: 6,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610950/HA/HA_0007_DJI_0489.00_02_55_11.Still010-min_gkzcdr.png"
    },
    {
      id: 7,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610950/HA/HA_0000_DJI_0489.00_01_33_18.Still011-min_ykefmx.png"
    },
    {
      id: 8,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610949/HA/HA_0001_IMG_6149-min_rdvneh.png"
    },
    {
      id: 9,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610949/HA/HA_0005_ha002-min_lvvn0m.png"
    }
  ],
  SGGRSS: [
    {
      id: 1,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610989/SGGRSS/Sggrss_0001_VagoaneSggrss013-min_nxfshz.png"
    },
    {
      id: 2,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610988/SGGRSS/Sggrss_0002_VagoaneSggrss012-min_jqt4qn.png"
    },
    {
      id: 3,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610988/SGGRSS/Sggrss_0000_VagoaneSggrss015-min_xb7o4u.png"
    },
    {
      id: 4,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610986/SGGRSS/Sggrss_0008_IMG_5785-min_ovpudj.png"
    },
    {
      id: 5,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610986/SGGRSS/Sggrss_0006_IMG_7677-min_flcoha.png"
    },
    {
      id: 6,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610986/SGGRSS/Sggrss_0004_IMG_7812-min_z7mka8.png"
    },
    {
      id: 7,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610986/SGGRSS/Sggrss_0007_IMG_5874-min_piu7tq.png"
    },
    {
      id: 8,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610986/SGGRSS/Sggrss_0003_VagoaneSggrss004-min_h3qiwr.png"
    },
    {
      id: 9,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610985/SGGRSS/Sggrss_0005_IMG_7685-min_htldcl.png"
    }
  ],
  EACS: [
    {
      id: 1,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710671/EACS/eacs016_yc3pdd.jpg"
    },
    {
      id: 2,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710670/EACS/eacs014_xfubnd.jpg"
    },
    {
      id: 3,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710670/EACS/eacs015_gol4zp.jpg"
    },
    {
      id: 4,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710670/EACS/eacs011_r47opg.jpg"
    },
    {
      id: 5,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710669/EACS/eacs010_qzk5oy.jpg"
    },
    {
      id: 6,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710668/EACS/eacs006_lmquar.jpg"
    },
    {
      id: 7,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710668/EACS/eacs013_o3vukw.jpg"
    },
    {
      id: 8,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710667/EACS/IMG_7005-min_wlguz0.jpg"
    },
    {
      id: 9,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710666/EACS/IMG_0632-min_evk7hp.jpg"
    },
    {
      id: 10,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710665/EACS/IMG_6914-min_mej2ye.jpg"
    },
    {
      id: 11,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710665/EACS/IMG_6977-min_byhwpl.jpg"
    },
    {
      id: 12,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710665/EACS/IMG_6901-min_v7tuxb.jpg"
    },
    {
      id: 13,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710665/EACS/IMG_0638-min_qigyb9.jpg"
    },
    {
      id: 14,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710664/EACS/IMG_0602-min_vruubr.jpg"
    },
    {
      id: 15,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710664/EACS/IMG_0571-min_xggrda.jpg"
    },
    {
      id: 16,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710664/EACS/IMG_0575-min_xyejek.jpg"
    },
    {
      id: 17,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710664/EACS/IMG_0580-min_giwpj4.jpg"
    },
    {
      id: 18,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710664/EACS/IMG_0568-min_lem399.jpg"
    },
    {
      id: 19,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710663/EACS/IMG_0548-min_cqnc72.jpg"
    },
    {
      id: 20,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710663/EACS/IMG_0563-min_i59vw8.jpg"
    }
  ]
};

export default imagesWagons