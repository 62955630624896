import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { MDBCol, MDBContainer, MDBRow, MDBIcon,MDBCard, MDBCardBody } from "mdbreact";
import teamImg1 from "../../assets/images/team1.png";
import teamImg2 from "../../assets/images/team2.png";
import teamImg3 from "../../assets/images/team3.png";
import teamImg4 from "../../assets/images/team4.png";
import teamImg5 from "../../assets/images/team5.png";
import teamImg6 from "../../assets/images/team6.png";
import teamImg7 from "../../assets/images/team7.png";
import teamImg8 from "../../assets/images/team8.png";
import teamImg9 from "../../assets/images/team9.png";
import teamImg10 from "../../assets/images/team10.png";


import './Team.css'

const ServicesPage = () => {
  const { t, i18n } = useTranslation();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <MDBContainer fluid className="epTeamBg">
      
        <MDBRow className="epFloatTextBox">
          <MDBCard className="my-5 px-5 pb-1 text-center epTeamCard">
            <MDBCardBody className="epFloatTextBox">
              <h1 className="epPageTitle1">
              {t("OurTeam.title")} <span className="epSpantitle">{t("OurTeam.titleSpan")}</span>
              </h1>
              <p className="my-5 ">{t("OurTeam.p1")}</p>
              
              <MDBRow className="text-md-left">
                <MDBCol lg="6" md="12" className="mb-5">
                  <MDBCol md="4" lg="6" className="float-left">
                  <img
                      src={teamImg4}
                      className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid"
                      tag="img"
                      alt="Sample avatar"
                    />
                  </MDBCol>
                  <MDBCol md="8" lg="6" className="float-right">
                    <h4 className="font-weight-bold mb-3">Ion Andrei</h4>
                    <h5 className="font-weight-bold grey-text mb-3">
                    Deputy General Manager
                    </h5>
                    <h6 className="font-weight-bold grey-text mb-3">+40 742 242 478</h6>
                    <h6 className="font-weight-bold grey-text mb-3">ion.andrei@e-prail.ro</h6>
                    
                    <a href="mailto:ion.andrei@e-prail.ro" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" icon="envelope" />
                    </a>
                    <a href="#" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" fab icon="linkedin" />
                    </a>
                  </MDBCol>
                </MDBCol>

                <MDBCol lg="6" md="12" className="mb-5">
                  <MDBCol md="4" lg="6" className="float-left">
                    <img
                      src={teamImg1}
                      className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid"
                      tag="img"
                      alt="Sample avatar"
                    />
                  </MDBCol>
                  <MDBCol md="8" lg="6" className="float-right">
                    <h4 className="font-weight-bold mb-3">Dan Iliescu</h4>
                    <h5 className="font-weight-bold grey-text mb-3">
                    Commercial Manager
                    </h5>
                    <h6 className="font-weight-bold grey-text mb-3">+40 727 725 630  </h6>
                    <h6 className="font-weight-bold grey-text mb-3">dan.iliescu@e-prail.ro  </h6>
                    
                    
                    <a href="mailto:dan.iliescu@e-prail.ro" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" icon="envelope" />
                    </a>
                    <a href="#" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" fab icon="linkedin" />
                    </a>
                  </MDBCol>
                </MDBCol>
              
                <MDBCol lg="6" md="12" className="mb-5">
                  <MDBCol md="4" lg="6" className="float-left">
                  <img
                      src={teamImg3}
                      className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid"
                      tag="img"
                      alt="Sample avatar"
                    />
                  </MDBCol>
                  <MDBCol md="8" lg="6" className="float-right">
                    <h4 className="font-weight-bold mb-3">Marius Bucur</h4>
                    <h5 className="font-weight-bold grey-text mb-3">
                    Sales Manager
                    </h5>
                    <h6 className="font-weight-bold grey-text mb-3">+40 727 725 630  </h6>
                    <h6 className="font-weight-bold grey-text mb-3">marius@e-prail.ro  </h6>
                    <a href="mailto:marius@e-prail.ro" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" icon="envelope" />
                    </a>
                    <a href="#" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" fab icon="linkedin" />
                    </a>
                  </MDBCol>
                </MDBCol>

                <MDBCol lg="6" md="12" className="mb-5">
                  <MDBCol md="4" lg="6" className="float-left">
                  <img
                      src={teamImg5}
                      className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid"
                      tag="img"
                      alt="Sample avatar"
                    />
                  </MDBCol>
                  <MDBCol md="8" lg="6" className="float-right">
                    <h4 className="font-weight-bold mb-3">Radu Ionescu</h4>
                    <h5 className="font-weight-bold grey-text mb-3">
                    Branch Manager Air&amp;Sea   
                    </h5>
                    <h6 className="font-weight-bold grey-text mb-3">+40 747 050 465</h6>
                    <h6 className="font-weight-bold grey-text mb-3">radu.ionescu@e-prail.ro </h6>
                    <a href="mailto:radu.ionescu@e-prail.ro" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" icon="envelope" />
                    </a>
                    <a href="#" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" fab icon="linkedin" />
                    </a>
                  </MDBCol>
                </MDBCol>

                <MDBCol lg="6" md="12" className="mb-5">
                  <MDBCol md="4" lg="6" className="float-left">
                  <img
                      src={teamImg6}
                      className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid"
                      tag="img"
                      alt="Sample avatar"
                    />
                  </MDBCol>
                  <MDBCol md="8" lg="6" className="float-right">
                    <h4 className="font-weight-bold mb-3">Mioara Burcu</h4>
                    <h5 className="font-weight-bold grey-text mb-3">
                    Pricing Manager 
                    </h5>
                    <h6 className="font-weight-bold grey-text mb-3">+40721 289 084</h6>
                    <h6 className="font-weight-bold grey-text mb-3">office@e-prail.ro</h6>
                    <a href="mailto:office@e-prail.ro" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" icon="envelope" />
                    </a>
                    <a href="#" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" fab icon="linkedin" />
                    </a>
                  </MDBCol>
                </MDBCol>


                <MDBCol lg="6" md="12" className="mb-5">
                  <MDBCol md="4" lg="6" className="float-left">
                  <img
                      src={teamImg7}
                      className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid"
                      tag="img"
                      alt="Sample avatar"
                    />
                  </MDBCol>
                  <MDBCol md="8" lg="6" className="float-right">
                    <h4 className="font-weight-bold mb-3">Anca Stefan</h4>
                    <h5 className="font-weight-bold grey-text mb-3">
                    Deputy Commercial Manager
                    </h5>
                    <h6 className="font-weight-bold grey-text mb-3">+40 756 116 159  </h6>
                    <h6 className="font-weight-bold grey-text mb-3">anca.stefan@e-prail.ro</h6>
                    <a href="mailto:anca.stefan@e-prail.ro" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" icon="envelope" />
                    </a>
                    <a href="#" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" fab icon="linkedin" />
                    </a>
                  </MDBCol>
                </MDBCol>

                <MDBCol lg="6" md="12" className="mb-5">
                  <MDBCol md="4" lg="6" className="float-left">
                  <img
                      src={teamImg8}
                      className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid"
                      tag="img"
                      alt="Sample avatar"
                    />
                  </MDBCol>
                  <MDBCol md="8" lg="6" className="float-right">
                    <h4 className="font-weight-bold mb-3">Daniel Chivu</h4>
                    <h5 className="font-weight-bold grey-text mb-3">
                    Forwarding And Planning Manager
                    </h5>
                    <h6 className="font-weight-bold grey-text mb-3">+40 733 696 146</h6>
                    <h6 className="font-weight-bold grey-text mb-3">daniel@e-prail.ro</h6>
                    <a href="mailto:daniel@e-prail.ro" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" icon="envelope" />
                    </a>
                    <a href="#" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" fab icon="linkedin" />
                    </a>
                  </MDBCol>
                </MDBCol>

                <MDBCol lg="6" md="12" className="mb-5">
                  <MDBCol md="4" lg="6" className="float-left">
                  <img
                      src={teamImg2}
                      className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid"
                      tag="img"
                      alt="Sample avatar"
                    />
                  </MDBCol>
                  <MDBCol md="8" lg="6" className="float-right">
                    <h4 className="font-weight-bold mb-3">Tomita Tarascu</h4>
                    <h5 className="font-weight-bold grey-text mb-3">
                    International Logistics Manager
                    </h5>
                    <h6 className="font-weight-bold grey-text mb-3">+40 733 063 322 </h6>
                    <h6 className="font-weight-bold grey-text mb-3">tomita.tarascu@e-prail.ro </h6>
                    <a href="mailto:tomita.tarascu@e-prail.ro" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" icon="envelope" />
                    </a>
                    <a href="#" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" fab icon="linkedin" />
                    </a>
                  </MDBCol>
                </MDBCol>
              
                <MDBCol lg="6" md="12" className="mb-5">
                  <MDBCol md="4" lg="6" className="float-left">
                  <img
                      src={teamImg9}
                      className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid"
                      tag="img"
                      alt="Sample avatar"
                    />
                  </MDBCol>
                  <MDBCol md="8" lg="6" className="float-right">
                    <h4 className="font-weight-bold mb-3">Georgiana Visan</h4>
                    <h5 className="font-weight-bold grey-text mb-3">
                    Public Relationship Manager
                    </h5>
                    <h6 className="font-weight-bold grey-text mb-3">+40 747 114 571  </h6>
                    <h6 className="font-weight-bold grey-text mb-3">georgiana@e-prail.ro</h6>
                    <a href="mailto:georgiana@e-prail.ro" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" icon="envelope" />
                    </a>
                    <a href="#" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" fab icon="linkedin" />
                    </a>
                  </MDBCol>
                </MDBCol>
                
                <MDBCol lg="6" md="12" className="mb-5">
                  <MDBCol md="4" lg="6" className="float-left">
                  <img
                      src={teamImg10}
                      className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid"
                      tag="img"
                      alt="Sample avatar"
                    />
                  </MDBCol>
                  <MDBCol md="8" lg="6" className="float-right">
                    <h4 className="font-weight-bold mb-3">Andreea Pascu</h4>
                    <h5 className="font-weight-bold grey-text mb-3">
                    Invoicing Manager
                    </h5>
                    <h6 className="font-weight-bold grey-text mb-3">+40 741 184 069    </h6>
                    <h6 className="font-weight-bold grey-text mb-3">andreea@e-prail.ro</h6>
                    <a href="mailto:andreea@e-prail.ro" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" icon="envelope" />
                    </a>
                    <a href="#" className="p-2 fa-lg email-ic">
                      <MDBIcon className="epTeamIcon" fab icon="linkedin" />
                    </a>
                  </MDBCol>
                </MDBCol>              
              
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBRow>
      </MDBContainer>
    </motion.div>
  );
};

export default ServicesPage;
