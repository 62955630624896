import React from "react";
import { MDBContainer, MDBRow} from "mdbreact";
//CSS
import { motion } from "framer-motion";
//Translation
import { useTranslation } from "react-i18next";
//Images Cards Component

import Card from './Card/Card';
import imagesWagons  from '../imagesWagons';


const WagonsPhotos = () => {
  const { t, i18n } = useTranslation();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <MDBContainer className="ePheader">
        <MDBRow>
          <h1 className="epPageTitle1 text-center my-5">
            {" "}
            {t("LocomotivesVideos.title")}{" "}
            <span className="epSpantitle">
              {" "}
              {t("WagonsPictures.titleSpan")}
            </span>
          </h1>
          <div className="ePdivider"></div>
        </MDBRow>
      </MDBContainer>
      <MDBContainer className="ePgalleryContainer">
        <MDBRow className="ePgalleryRow">
          {Object.keys(imagesWagons).map((item) => <Card   thumb={imagesWagons[item][0].src} name={item} key={imagesWagons[item][0].id}/> )}
          
        
          
        </MDBRow>
      </MDBContainer>
    </motion.div>
  );
};

export default WagonsPhotos;
