import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import {Link} from "react-router-dom"
//Styles
import "./RealwayFleet.css";



const RealwayFleet = () => {
    const { t, i18n } = useTranslation();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
    <MDBContainer fluid className="epTitleContainer">
    <h1 className="epPageTitle1">{t("Fleet.pageTitle")} <span className="epSpantitle">{t("Fleet.pageSpan")}</span> </h1>
    <MDBRow className="epAboutSecondRow">
        <MDBCol className="epRealText" size="6" >
            <h1>{t("Fleet.vagoaneTitle")}</h1>
            <p>{t("Fleet.vagoaneText")}</p>
            <Link to="/train_photos" className="epCardButton">{t("About.fleetBtn")}</Link>
        </MDBCol>
        <MDBCol className="epRealText" size="6">
            <h1>{t("Fleet.locomotiveTitle")}</h1>
            <p> {t("Fleet.locomotiveText")}</p>
            <Link to="/train_photos" className="epCardButton">{t("About.fleetBtn")}</Link>
        </MDBCol>
    </MDBRow>
    </MDBContainer>
    <div className="bigBox">
      
    
    </div>
    </motion.div>
  );
};

export default RealwayFleet;
