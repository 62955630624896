import React from "react";
import { useParams } from "react-router-dom";
import imagesWagons from "../imagesWagons";
import { SRLWrapper } from "simple-react-lightbox";
import { MDBCol, MDBRow, MDBContainer } from "mdbreact";
const WagonsPages = () => {
  let { id } = useParams();
  const test = () => {
    const items = imagesWagons[id];
    return (
      <SRLWrapper>
        <MDBContainer>
          <MDBRow>
          
            <h1 className="epPageTitle1 text-center my-5">{id}</h1>
          </MDBRow>

          <MDBRow className="epRowPoze">
            {items.map(i => {
              return (
                <MDBCol md={i.col} size="auto" >
                  <img
                    className="img-thumbnail epPoze img-fluid  z-depth-1-half"
                    src={i.src}
                    alt=""
                  />
                </MDBCol>
              );
            })}
          </MDBRow>
        </MDBContainer>
      </SRLWrapper>
    );
  };
  return <>{test()}</>;
};

export default WagonsPages;
