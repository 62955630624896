import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { MDBCol, MDBContainer, MDBRow, MDBIcon } from "mdbreact";

import img1 from "../../assets/images/img10.jpg";
import "./ServicesPage.css";
const ServicesPage = () => {
    const { t, i18n } = useTranslation();
    return(
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >

<MDBContainer fluid className="epTitleContainer">
    <h1 className="epPageTitle1">{t("Services.title")}</h1>
    <MDBRow className="epFloatTextBox">
        <MDBCol className="epServiceText" size="6" xs="2" >
        <h6>{t("Services.p1")}
</h6>
              <ul>
                <li><MDBIcon icon="angle-right"/>{t("Services.pL1")}</li>
                <li><MDBIcon icon="angle-right"/>{t("Services.pL2")}</li>
                <li><MDBIcon icon="angle-right"/>{t("Services.pL3")}</li>
                <li><MDBIcon icon="angle-right"/>{t("Services.pL4")}</li>
                <li><MDBIcon icon="angle-right"/>{t("Services.pL5")}</li>
              </ul>
        </MDBCol>
        <MDBCol className="epRealText" size="6" xs="2">
            <img className="img-fluid" src={img1}/>
        </MDBCol>
    </MDBRow>
    </MDBContainer>
    <MDBContainer fluid className="epServiceCTA">
    <h1>{t("About.promiseT")}</h1>
    <div className="devider"></div>
    <p className="epCTAText"><p>{t("Services.ctaText")}</p></p>


    </MDBContainer>
      
      </motion.div>
    );
}

export default ServicesPage