import React from "react";
import { MDBCol, MDBCard, MDBCardImage, MDBCardBody } from "mdbreact";
import {Link} from "react-router-dom";


const CardVideo =( props) => {
  return (
    <MDBCol size="auto" xs="4" md="4" >
      <MDBCard  className="epCardPoze">
        <MDBCardImage
         className="img-fluid epCardImgPoze"
          src={props.thumb}
          waves
        />
        <MDBCardBody className="epCardBody">
        <Link className="epCardBtn" to={`/wagons_video/${props.name}`}>{props.name} Video</Link>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  );
};

export default CardVideo;
