import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBInput,
  MDBContainer
} from "mdbreact";

import { motion } from "framer-motion";

import { useTranslation } from "react-i18next";

const Gdpr = () => {
  const { t, i18n } = useTranslation();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <MDBContainer fluid className="epContactBg py-5">
        <h1 className="epPageTitle1 text-center my-5">
         GDPR
        </h1>

        <p>
          Politica de Confidentialitate E-P Rail SRL se angajeaza sa nu
          transmita datele personale ale utilizatorilor site-ului catre terti si
          sa le foloseasca numai in scopul stabilirii contactului cu clientii
          sai, precum si in informarea acestora asupra aspectelor legate de
          functionarea site-ului si a ofertelor acestuia.E-P Rail SRL poate
          notifica utilizatorii privind ofertele curente prin newsletterul sau,
          si poate trimite felicitari, cupoane cadou sau alte
          mesaje.Utilizatorii website-ului nu sunt obligati sa furnizeze datele
          personale, insa acest refuz va conduce la imposibilitatea livrarii
          comenzilor.Orice utilizator care a furnizat explicit adresa sa de
          e-mail pe site-ul e-prail.ro poate opta pentru stergerea acestuia din
          baza noastra de date.Conform Legii nr 677/2001, utilizatorul
          beneficiaza de dreptul de acces, de interventie asupra datelor,
          dreptul de a nu fi supus unei decizii individuale si deptul de a se
          adresa justitiei. Totodata, utilizatorul are dreptul sa se opuna
          prelucrarii datelor personale care il privesc si sa solicite
          modificarea sau stergerea datelor. Pentru exercitarea acestor
          drepturi, utilizatorul se poate adresa cu o cerere scrisa, datata si
          semnata pe cale telefonica sau posta electronica (e-mail).Daca unele
          din datele despre dumneavoastra sunt incorecte, va rugam sa ne
          informati cat mai curand. Observatie: Orice persoana are dreptul de a
          se opune, pentru motive legitime, la prelucrarea datelor ce o privesc.
          Acest drept de opozitie poate fi exclus pentru anumite prelucrari
          prevazute de lege (ex: prelucrari efectuate de serviciile financiare
          si fiscale, politie, justitie, securitatee sociala). Prin urmare,
          aceasta mentiune nu poate figura daca prelucrarea are un caracter
          obligatoriu. De asemenea orice persoana are dreptul de a se opune in
          mod gratuit si fara nici o justificare la prelucrarea datelor
          personale in orice scop. Securitatea datelor cu carácter personal
          E-P Rail.ro certifica faptul ca indeplineste cerintele minime de
          securitate a datelor cu caracter personal.E-P Rail.ro utilizeaza
          metode si technologii de securitate, impreuna cu politici aplicate
          salariatiilor si proceduri de lucru, inclusiv de control si audit
          pentru protejarea datelor cu caracter personal, colectate conform
          prevederilor legale in vigoare. Serverele pe care site-ul este gazduit
          sunt protejate de acces fizic si la distanta limitat, fiind instalate
          in incinte adecvate tehnic si din punctul de vedere al securitatii.
          Depunem toate eforturile rezonabile, justificate comercial pentru a va
          proteja datele cu caracter personal colectate, analizam noile
          technologii in domeniu si atunci si daca este cazul, le aplicam in
          vederea upgrade-ului sistemelor noatre de securitate. Masurile de
          securitarte a datelor cu caracter personal sunt stabile astfel incat
          sa asigure un nivel adecvat de securitate a datelor cu caracter
          personal.
        </p>
      </MDBContainer>
    </motion.div>
  );
};

export default Gdpr;
