import React from "react";
import "./SMI.css";

import { ReactComponent as FacebookIcon } from "../../assets/images/facebook.svg";
import { ReactComponent as InstaIcon } from "../../assets/images/instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/images/linkedin.svg";
import { ReactComponent as YouTubeIcon } from "../../assets/images/youtube.svg";

const SMI = () => {
  return (
    <>
      <div class="social-links">
        <div class="social-btn flex-center" id="twitter">
          <FacebookIcon className="svgStyle"/>
          <span>E-P Rail</span>
        </div>

        <div class="social-btn flex-center" id="linkedin">
          <InstaIcon className="svgStyle" />
          <span>E-P Rail</span>
        </div>

        <div class="social-btn flex-center" id="github">
          <LinkedinIcon className="svgStyle" />
          <span>E-P Rail</span>
        </div>
        <div class="social-btn flex-center" id="github">
          <YouTubeIcon className="svgStyle" />
          <span>E-P Rail</span>
        </div>
      </div>
    </>
  );
};

export default SMI;
