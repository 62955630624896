import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { layoutGenerator } from 'react-break'
import './Menuv2.css'

const Ul = styled.ul`
position: absolute;
top: 50% !important;
left: 50%;
width: 100vw !important;
height: 100vh !important;
background-color: #fff;
z-index: 300;
overflow: hidden;
margin: 0 !important;
padding: 0 !important;
transform: ${({ open }) => open ? 'translate(-50% ,-50%)' : 'translateY(0%)'};
display:${({open}) => open ? 'block' : 'none'};
transition:  all 1s ease-in-out;
  
  @media (max-width: 376px) {
    flex-flow: column nowrap;
    
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;

    
  }
`;

const RightNav = ({ open, toggle }) => {
  const { t, i18n } = useTranslation();
  const layout = layoutGenerator({
    mobile: 0,
    desktop:768
  });
  const OnMobile = layout.is('mobile');
  const OnDesktop = layout.is('desktop');

  return (
    <Ul open={open} className="epNav">
      
        <OnMobile className="epMobileVersion">
          <ul className="epMobileList">
        <NavLink to="/" activeClassName="active" onClick={toggle}><li>{t ('Menu.1')}</li></NavLink>
        <NavLink to="/about" activeClassName="active" onClick={toggle}><li>{t ('Menu.2')}</li></NavLink>
        <NavLink to="/train_photos" activeClassName="active" onClick={toggle}><li>{t ('Menu.6')}</li></NavLink>
        <NavLink to="/train_videos" activeClassName="active" onClick={toggle}><li>{t ('Menu.7')}</li></NavLink>
        <li><a href="https://eprail.blogspot.com/" target="_blank">{t ('Menu.3')}</a></li>
        <NavLink to="/contact" activeClassName="active" onClick={toggle}><li>{t ('Menu.4')}</li></NavLink>
        <NavLink to="/login" activeClassName="active"  onClick={toggle}><li>{t ('Menu.5')}</li></NavLink>
      </ul>
        </OnMobile>
        <OnDesktop>
        <div className="pt-table desktop-768">
                <div className="pt-tablecell page-home relative" >
                  <div className="overlay"></div>

                  <div className="epMenu">
                    <div className="row">
                      <div >

                        <div className="hexagon-menu clear">
                          <div className="hexagon-item" onClick={toggle}>
                            <div className="hex-item">
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <div className="hex-item">
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <Link to="/"  className="hex-content">
                              <span className="hex-content-inner">
                                <span className="icon">
                                  <i className="fa fa-universal-access"></i>
                                </span>
                                <span className="title">{t ('Menu.1')}</span>
                              </span>
                              <svg viewBox="0 0 173.20508075688772 200" height="200" width="174" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530"></path></svg>
                            </Link>
                          </div>
                          <div className="hexagon-item" onClick={toggle}>
                            <div className="hex-item">
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <div className="hex-item">
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <Link to="/about"  className="hex-content">
                              <span className="hex-content-inner">
                                <span className="icon">
                                  <i className="fa fa-bullseye"></i>
                                </span>
                                <span className="title">{t ('Menu.2')}</span>
                              </span>
                              <svg viewBox="0 0 173.20508075688772 200" height="200" width="174" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530"></path></svg>
                              </Link>
                          </div>
                          <div className="hexagon-item" onClick={toggle}>
                            <div className="hex-item">
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <div className="hex-item">
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <a href="https://eprail.blogspot.com/" target="_blank" className="hex-content">
                              <span className="hex-content-inner">
                                <span className="icon">
                                  <i className="fa fa-braille"></i>
                                </span>
                                <span className="title">{t ('Menu.3')}</span>
                              </span>
                              <svg viewBox="0 0 173.20508075688772 200" height="200" width="174" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530"></path></svg>
                            </a>
                          </div>
                          <div className="hexagon-item" onClick={toggle}>
                            <div className="hex-item">
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <div className="hex-item">
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <Link to='/contact' className="hex-content">
                              <span className="hex-content-inner">
                                <span className="icon">
                                  <i className="fa fa-id-badge"></i>
                                </span>
                                <span className="title">{t ('Menu.4')}</span>
                              </span>
                              <svg viewBox="0 0 173.20508075688772 200" height="200" width="174" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530"></path></svg>
                            </Link>
                          </div>
                          <div className="hexagon-item" onClick={toggle}>
                            <div className="hex-item">
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <div className="hex-item">
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <Link to='/team' className="hex-content">
                              <span className="hex-content-inner">
                                <span className="icon">
                                  <i className="fa fa-life-ring"></i>
                                </span>
                                <span className="title">{t ('Menu.5')}</span>
                              </span>
                              <svg viewBox="0 0 173.20508075688772 200" height="200" width="174" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530"></path></svg>
                            </Link>
                          </div>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </OnDesktop>
    </Ul>
  )
}

export default RightNav
