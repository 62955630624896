import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import {useTranslation} from "react-i18next"
import "./Footer.css";
import { ReactComponent as FooterSvg } from "../../assets/images/footer_animation.svg";
import SMI from '../SMI/SMI'
const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <MDBFooter className="epFooter">
      <MDBContainer fluid className="text-center text-md-left ">
        <MDBRow>
          <MDBCol className="epFooterText" md="6">
            <h5 className="title ">Contact</h5>
            <p>
            {t("Footer.p1")}<br/> 
            {t("Footer.p2")}<br/>
              +40 31 420 40 28 <br/>
              office@e-prail.ro<br/>
              www.e-prail.ro
            </p>
            <SMI className="SMI"/>
          </MDBCol>
          <MDBCol md="6">
            <FooterSvg className="svgContact" width="507" height="304" />
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright: E-P Rail S.R.L.
        </MDBContainer>
      </div>
    </MDBFooter>
  );
};

export default Footer;
