const images = {
  LEMA039: [
    {
      id: 1,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709176/LEMA039/IMG_0365_dn2eqh.jpg"
    },
    {
      id: 2,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709175/LEMA039/IMG_0359_asnx5x.jpg"
    },
    {
      id: 3,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709175/LEMA039/lema39016_hh775o.jpg"
    },
    {
      id: 4,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709175/LEMA039/IMG_0466_mf2whs.jpg"
    },
    {
      id: 5,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709175/LEMA039/IMG_0435_qqlvaw.jpg"
    },
    {
      id: 6,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709174/LEMA039/lema39010_srwvzx.jpg"
    },
    {
      id: 7,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709174/LEMA039/lema39007_lybanx.jpg"
    },
    {
      id: 8,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709174/LEMA039/IMG_0439_btcq1g.jpg"
    },
    {
      id: 9,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709174/LEMA039/IMG_0419_apvzp7.jpg"
    },
    {
      id: 10,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709173/LEMA039/IMG_0369_qusdog.jpg"
    },
    {
      id: 11,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709173/LEMA039/IMG_0410_hmmt0l.jpg"
    },
    {
      id: 12,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709173/LEMA039/IMG_0403_rn7jd3.jpg"
    },
    {
      id: 13,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709173/LEMA039/IMG_0371_hi9lnv.jpg"
    },
    {
      id: 14,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709173/LEMA039/IMG_0375_cyhr51.jpg"
    },
    {
      id: 15,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709173/LEMA039/IMG_0400_mkawbm.jpg"
    }
  ],
  LEMA038: [
    {
      id: 1,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709204/LEMA038/lema038002_xtmkgz.jpg"
    },
    {
      id: 2,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709204/LEMA038/lema038013_jxkyub.jpg"
    },
    {
      id: 3,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709204/LEMA038/IMG_7648_vivgaa.jpg"
    },
    {
      id: 4,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709204/LEMA038/IMG_7634_kco6yw.jpg"
    },
    {
      id: 5,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709203/LEMA038/IMG_7632_ma4at8.jpg"
    },
    {
      id: 6,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709203/LEMA038/IMG_7594_hmhhpe.jpg"
    },
    {
      id: 7,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709203/LEMA038/IMG_7589_s0yxcg.jpg"
    },
    {
      id: 8,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709203/LEMA038/IMG_7576_esmt92.jpg"
    },
    {
      id: 9,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709203/LEMA038/IMG_7581_lkmzov.jpg"
    },
    {
      id: 10,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709202/LEMA038/IMG_7572_aemazm.jpg"
    },
    {
      id: 11,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709202/LEMA038/IMG_7569_np9wyy.jpg"
    },
    {
      id: 12,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709202/LEMA038/IMG_7424_kcop2c.jpg"
    },
    {
      id: 13,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709201/LEMA038/IMG_5790_nfdrhs.jpg"
    },
    {
      id: 14,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709201/LEMA038/IMG_5780_rudpvm.jpg"
    },
    {
      id: 15,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709201/LEMA038/IMG_5787_akcgaq.jpg"
    },
    {
      id: 16,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709201/LEMA038/IMG_5786_pmdowt.jpg"
    },
    {
      id: 17,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709201/LEMA038/IMG_5792_qssqip.jpg"
    },
    {
      id: 18,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709200/LEMA038/IMG_7412_hjzxen.jpg"
    }
  ],
  LEMA037: [
    {
      id: 1,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709233/LEMA037/IMG_5896_uoef6l.jpg"
    },
    {
      id: 2,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709232/LEMA037/IMG_5889_pc4two.jpg"
    },
    {
      id: 3,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709232/LEMA037/lema37008_kp9wky.jpg"
    },
    {
      id: 4,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709231/LEMA037/lema37004_gdnobr.jpg"
    },
    {
      id: 5,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709231/LEMA037/IMG_6041_mlt0jn.jpg"
    },
    {
      id: 6,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709231/LEMA037/IMG_5980_fqx2aw.jpg"
    },
    {
      id: 7,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709231/LEMA037/IMG_5951_ue9hwl.jpg"
    },
    {
      id: 8,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709231/LEMA037/IMG_5972_zo7gh8.jpg"
    },
    {
      id: 9,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709231/LEMA037/IMG_5967_pyk2vn.jpg"
    },
    {
      id: 10,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709230/LEMA037/IMG_5946_qr4iy3.jpg"
    },
    {
      id: 11,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709229/LEMA037/IMG_5930_zfvoht.jpg"
    },
    {
      id: 12,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709229/LEMA037/IMG_5919_tsij4j.jpg"
    },
    {
      id: 13,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709229/LEMA037/IMG_5903_g96b1z.jpg"
    },
    {
      id: 14,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709229/LEMA037/IMG_5900_enxrse.jpg"
    },
    {
      id: 15,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709229/LEMA037/IMG_5909_aizrwk.jpg"
    },
    {
      id: 16,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709229/LEMA037/IMG_5912_taejef.jpg"
    }
  ],
  LEMA036: [
    {
      id: 1,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709290/LEMA036/lema36014_zouqea.jpg"
    },
    {
      id: 2,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709290/LEMA036/lema36017_tmrun1.jpg"
    },
    {
      id: 3,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709289/LEMA036/IMG_7805_vbz4ic.jpg"
    },
    {
      id: 4,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709289/LEMA036/IMG_7771_wjfioe.jpg"
    },
    {
      id: 5,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709289/LEMA036/lema36006_jgkpqx.jpg"
    },
    {
      id: 6,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709289/LEMA036/IMG_7787_sjaapx.jpg"
    },
    {
      id: 7,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709289/LEMA036/IMG_7773_tkxgjh.jpg"
    },
    {
      id: 8,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709289/LEMA036/IMG_7768_atfafu.jpg"
    },
    {
      id: 9,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709288/LEMA036/IMG_7755_tfgsqa.jpg"
    },
    {
      id: 10,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709288/LEMA036/IMG_7764_ffdb00.jpg"
    },
    {
      id: 11,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709288/LEMA036/IMG_7738_fba1hc.jpg"
    },
    {
      id: 12,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709288/LEMA036/IMG_7715_jits23.jpg"
    },
    {
      id: 13,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709287/LEMA036/IMG_7724_qkaxye.jpg"
    },
    {
      id: 14,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709287/LEMA036/IMG_7702_dbnyuv.jpg"
    },
    {
      id: 15,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709286/LEMA036/IMG_7709_zfasjx.jpg"
    },
    {
      id: 16,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709286/LEMA036/IMG_7707_rql9qt.jpg"
    },
    {
      id: 17,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709286/LEMA036/IMG_7698_xqy9ii.jpg"
    },
    {
      id: 18,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709286/LEMA036/IMG_7719_emlb84.jpg"
    }
  ],
  LEMA035: [
    {
      id: 1,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709402/LEMA035/IMG_6572_xfu683.jpg"
    },
    {
      id: 2,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709402/LEMA035/IMG_6597_gzow6i.jpg"
    },
    {
      id: 3,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709402/LEMA035/IMG_6594_accad3.jpg"
    },
    {
      id: 4,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709402/LEMA035/IMG_6592_rkpdpv.jpg"
    },
    {
      id: 5,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709401/LEMA035/IMG_6560_bmtfy1.jpg"
    },
    {
      id: 6,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709400/LEMA035/IMG_6536_gomupq.jpg"
    },
    {
      id: 7,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709400/LEMA035/IMG_6553_euiru5.jpg"
    },
    {
      id: 8,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709399/LEMA035/IMG_6526_swdnia.jpg"
    },
    {
      id: 9,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709399/LEMA035/IMG_6523_ia7dwa.jpg"
    },
    {
      id: 10,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709399/LEMA035/IMG_6495_ianw86.jpg"
    },
    {
      id: 11,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709398/LEMA035/IMG_6501_ekxvzy.jpg"
    },
    {
      id: 12,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709398/LEMA035/IMG_6520_vxek1h.jpg"
    },
    {
      id: 13,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709397/LEMA035/IMG_6516_hvyluk.jpg"
    },
    {
      id: 14,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709397/LEMA035/IMG_6491_dn7psw.jpg"
    },
    {
      id: 15,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709396/LEMA035/IMG_6392_ropcwu.jpg"
    },
    {
      id: 16,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709396/LEMA035/IMG_6484_yzmd6j.jpg"
    },
    {
      id: 17,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709395/LEMA035/IMG_6394_sukfdf.jpg"
    },
    {
      id: 18,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709395/LEMA035/IMG_6481_yiobrq.jpg"
    },
    {
      id: 19,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709395/LEMA035/IMG_6399_umskjl.jpg"
    },
    {
      id: 20,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709394/LEMA035/IMG_6398_zy1osi.jpg"
    },
    {
      id: 21,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709393/LEMA035/IMG_6388_owjbem.jpg"
    },
    {
      id: 22,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709393/LEMA035/IMG_6477_ciyrgb.jpg"
    }
  ],
  LEMA048: [
    {
      id: 1,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709432/LEMA048/lema048015_dthrse.jpg"
    },
    {
      id: 2,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709432/LEMA048/lema048014_thujjh.jpg"
    },
    {
      id: 3,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709432/LEMA048/lema048012_t8ydfw.jpg"
    },
    {
      id: 4,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709431/LEMA048/lema048006_kogpfc.jpg"
    },
    {
      id: 5,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709431/LEMA048/IMG_0631_kavkse.jpg"
    },
    {
      id: 6,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709431/LEMA048/lema048005_jljwr4.jpg"
    },
    {
      id: 7,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709431/LEMA048/lema048011_rxtzcx.jpg"
    },
    {
      id: 8,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709430/LEMA048/lema048001_osuczt.jpg"
    },
    {
      id: 9,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709430/LEMA048/IMG_0628_b6vlje.jpg"
    },
    {
      id: 10,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709429/LEMA048/IMG_0547_j5mfgh.jpg"
    },
    {
      id: 11,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709429/LEMA048/IMG_0621_vuosee.jpg"
    },
    {
      id: 12,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709429/LEMA048/IMG_0541_rkjyca.jpg"
    },
    {
      id: 13,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709429/LEMA048/IMG_0556_tlcis4.jpg"
    },
    {
      id: 14,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709429/LEMA048/IMG_0538_tg3fbn.jpg"
    },
    {
      id: 15,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709429/LEMA048/IMG_0605_srfy72.jpg"
    },
    {
      id: 16,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709428/LEMA048/IMG_0525_jm9brf.jpg"
    }
  ],
  S004: [
    {
      id: 1,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710617/S004/IMG_9082_imbl7a.jpg"
    },
    {
      id: 2,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710617/S004/ST004014_s2jxhh.jpg"
    },
    {
      id: 3,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710616/S004/IMG_9181_inxwpt.jpg"
    },
    {
      id: 4,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710616/S004/ST004013_xj0tv0.jpg"
    },
    {
      id: 5,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710616/S004/ST004009_n5nvmk.jpg"
    },
    {
      id: 6,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710616/S004/IMG_9115_go1hne.jpg"
    },
    {
      id: 7,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710616/S004/ST004012_upo2p7.jpg"
    },
    {
      id: 8,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710615/S004/ST004003_otjlee.jpg"
    },
    {
      id: 9,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710615/S004/ST004006_qyokft.jpg"
    },
    {
      id: 10,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710615/S004/IMG_9109_j0wnlb.jpg"
    },
    {
      id: 11,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710615/S004/IMG_9103_utneeh.jpg"
    },
    {
      id: 12,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710614/S004/IMG_9107_n8fc5a.jpg"
    },
    {
      id: 13,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710614/S004/IMG_9108_cmon7v.jpg"
    },
    {
      id: 14,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710614/S004/IMG_9087_bjgnqc.jpg"
    },
    {
      id: 15,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710614/S004/IMG_9086_rti6j1.jpg"
    },
    {
      id: 16,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710614/S004/IMG_9094_uelavq.jpg"
    }
  ],
  S005: [
    {
      id: 1,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710573/S005/Smartron005020_huuwhj.jpg"
    },
    {
      id: 2,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710572/S005/Smartron005016_n9ujh4.jpg"
    },
    {
      id: 3,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710572/S005/Smartron005012_yg2d0w.jpg"
    },
    {
      id: 4,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710571/S005/IMG_0331_qop96f.jpg"
    },
    {
      id: 5,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710571/S005/Smartron005001_uq5w3j.jpg"
    },
    {
      id: 6,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710571/S005/Smartron005003_gjxy7c.jpg"
    },
    {
      id: 7,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710571/S005/IMG_0338_g3r3ay.jpg"
    },
    {
      id: 8,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710571/S005/IMG_0346_oxlp19.jpg"
    },
    {
      id: 9,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710570/S005/IMG_0326_lwlr2o.jpg"
    },
    {
      id: 10,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710570/S005/IMG_0315_jxgnbm.jpg"
    },
    {
      id: 11,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710570/S005/IMG_0299_i6yhtr.jpg"
    },
    {
      id: 12,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710570/S005/IMG_0254_mhmie9.jpg"
    },
    {
      id: 13,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710569/S005/IMG_0243_cvjwqm.jpg"
    },
    {
      id: 14,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710569/S005/IMG_0167_aeoogm.jpg"
    },
    {
      id: 15,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710569/S005/IMG_0181_hvkdga.jpg"
    },
    {
      id: 16,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710569/S005/IMG_0131_btoaml.jpg"
    },
    {
      id: 17,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710569/S005/IMG_0112_vo2w9y.jpg"
    },
    {
      id: 18,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710568/S005/IMG_0172_jyl67f.jpg"
    }
  ],
  S006: [
    {
      id: 1,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710603/S006/ST006015_pklk1i.jpg"
    },
    {
      id: 2,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710602/S006/ST006009_l2s5gz.jpg"
    },
    {
      id: 3,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710602/S006/ST006012_ahfd15.jpg"
    },
    {
      id: 4,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710602/S006/ST006008_sigksy.jpg"
    },
    {
      id: 5,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710602/S006/ST006014_sx32pe.jpg"
    },
    {
      id: 6,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710601/S006/ST006005_in1nqz.jpg"
    },
    {
      id: 7,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710601/S006/ST006007_qvti2l.jpg"
    },
    {
      id: 8,
      col: "6",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710601/S006/ST006001_wvozty.jpg"
    },
    {
      id: 9,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710601/S006/ST006003_dkgbkp.jpg"
    },
    {
      id: 10,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710600/S006/IMG_9214_acmsar.jpg"
    },
    {
      id: 11,
      col: "4",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710600/S006/IMG_9187_hy8u1p.jpg"
    },
    {
      id: 12,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710600/S006/IMG_9206_qgxvvb.jpg"
    },
    {
      id: 13,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710600/S006/IMG_9194_qojsoj.jpg"
    },
    {
      id: 14,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710599/S006/IMG_9182_a4wmt9.jpg"
    },
    {
      id: 15,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710599/S006/IMG_9125_j4bkbo.jpg"
    },
    {
      id: 16,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710599/S006/IMG_9129_btwd6u.jpg"
    },
    {
      id: 17,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710599/S006/IMG_9136_zdxye0.jpg"
    },
    {
      id: 18,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710598/S006/IMG_9165_kh4t0c.jpg"
    },
    {
      id: 19,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710598/S006/IMG_9169_ewbnm2.jpg"
    },
    {
      id: 20,
      col: "3",
      src:
        "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710598/S006/IMG_9141_bmhm2i.jpg"
    }
  ]
};

export default images;
