import React from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//Components
import {AnimatePresence, motion} from "framer-motion"
//Pages
import Header from "./components/Header/Menu/Menu";
import Footer from './components//Footer/Footer';
import Home from './pages/Home/Home';
import AboutUs from './pages/AboutUs/AboutUs';
import TrainPhotos from './pages/TrainPhotos/TrainPhotos';
import TrainVideos from './pages/TrainVideos/TrainVideos';
import TrainPages from './pages/TrainPages'
import RealwayFleet from './pages/RealwayFleet/RealwayFleet';
import ServicesPage from "./pages/ServicesPage/ServicesPage";
import Team from "./pages/Team/Team";
import Login from "./pages/Login/Login";
import Contact from "./pages/Contact/Contact";
import TrainVideoPage from './pages/TrainVideos/TrainVideoPage';

import WagonsPhotos from './pages/WagonsPhotos/WagonsPhotos'
import WagonsPhotoPage from './pages/WagonsPhotos/WagonsPages'

import WagonsVideos from './pages/WagonsVideos/WagonsVideos'
import WagonsVideoPage from './pages/WagonsVideos/WagonsVideoPage'

import Gdpr from './pages/Agree/GDPR/Gdpre';


//CSS

import SimpleReactLightbox from 'simple-react-lightbox'

//De facut meniul functiolan


function App() {
  
  const { t, i18n } = useTranslation();
  
  function translationHandle(lang){
    i18n.changeLanguage(lang);
  }

  
  return (
<SimpleReactLightbox>
    <Router>
      <div className="App">
        <Header translationHandle={translationHandle} />
        
        <main id='content'>
<AnimatePresence exitBeforeEnter> 
          
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" component={AboutUs} />
            <Route path="/realway" component={RealwayFleet} />
            <Route path="/service" component={ServicesPage} />
            <Route path="/team" component={Team} />
            <Route path="/login" component={Login} />
            <Route path="/contact" component={Contact} />
            <Route exact path="/train_photos" component={TrainPhotos}/>
            <Route exact path="/train_videos" component={TrainVideos}/>
            <Route path="/train_photos/:id" component={TrainPages}/>
            <Route path="/train_video/:id" component={TrainVideoPage}/>
            <Route exact path="/wagons_photos" component={WagonsPhotos}/>
            <Route exact path="/wagons_videos" component={WagonsVideos}/>
            <Route path="/wagons_photos/:id" component={WagonsPhotoPage}/>
            <Route path="/wagons_video/:id" component={WagonsVideoPage}/>

            <Route path="/gdpr" component={Gdpr}/>

          </Switch>
          </AnimatePresence>
        </main>
        
        <Footer />
      </div>
    </Router>
    </SimpleReactLightbox>
  );
}

export default App;
