import React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import {motion} from 'framer-motion'
import { MDBContainer, MDBRow} from "mdbreact";


import videosWagons from '../videoWagons';
import CardVideo from './CardVideo/CardVideoW';

import { useTranslation } from "react-i18next";

const WagonsVideos = () => {
  const { t, i18n } = useTranslation();
  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    <MDBContainer className="ePheader py-5">
        <MDBRow className="epRailLocomotive">
        <h1 className="epPageTitle1 text-center my-5"> {t("LocomotivesVideos.title")} <span className="epSpantitle"> {t("LocomotivesVideos.titleSpan")}</span></h1>
           
        </MDBRow>
        <MDBRow>
        {Object.keys(videosWagons).map((item) => <CardVideo thumb={videosWagons[item][0].thumb} name={item} key={videosWagons[item][0].id}/> )}
        </MDBRow>
    </MDBContainer>

   </motion.div>
  );
};

export default WagonsVideos;
