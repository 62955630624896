import React from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBIcon, MDBInput, MDBContainer } from "mdbreact";
import './Contact.css';
import { motion } from "framer-motion";
import {Link} from 'react-router-dom';
import emailjs from 'emailjs-com'
import { useTranslation } from "react-i18next";

const ContactPage = () => {

  const { t, i18n } = useTranslation();

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('gmail', 'template_676wfn9', e.target, 'user_YdzNQvKoM23CHD9PGvvXj')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }

  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    <MDBContainer fluid className="epContactBg py-5">
    <h1 className="epPageTitle1 text-center my-5"> {t("Contact.title")} <span className="epSpantitle"> {t("Contact.titleSpan")}</span></h1>
    
      <MDBRow className="py-5">
        <MDBCol lg="5" className="lg-0 mb-4">
          <MDBCard>
            <MDBCardBody>
              <div className="form-header epContactHeader accent-1">
                <h3 className="mt-2">
                  <MDBIcon icon="envelope" /> {t("Contact.placeHolder")}
                </h3>
              </div>
              <p className="dark-grey-text">
              {t("Contact.p1")}
              </p>
              <form onSubmit={sendEmail} noValidate>
              <div className="md-form">
                <MDBInput
                  icon="user"
                  label= {t("Contact.lName")}
                  iconClass="grey-text"
                  type="text"
                  id="form-name"
                  name="name"
                 
                />
              </div>
              <div className="md-form">
                <MDBInput
                  icon="envelope"
                  label={t("Contact.lEmail")}
                  iconClass="grey-text"
                  type="text"
                  id="form-email"
                  name="email"
                  
                />
              </div>
              <div className="md-form">
                <MDBInput
                  icon="tag"
                  label={t("Contact.lSubject")}
                  iconClass="grey-text"
                  type="text"
                  id="form-subject"
                  name="subject"
                />
              </div>

              

              <div className="md-form">
                <MDBInput
                  icon="pencil-alt"
                  label={t("Contact.lMessage")}
                  iconClass="grey-text"
                  type="textarea"
                  id="form-text"
                  name="message"
                />
              </div>
              <div className="agree">
              <input
                className="custom-control-input"
                type="checkbox"
                value=""
                id="invalidCheck"
                required
              />
              <label className="custom-control-label" htmlFor="invalidCheck">
                <Link to="/gdpr">{t("Contact.lAgree")}</Link>
              </label>
              </div>

              <div className="text-center">
              <input
                  label="Trimite"
                  className="epCardButton"
                  type="submit"
                  value={t("Contact.lSend")}
                />
      
              </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol lg="7">
          <div
            id="map-container"
            className="rounded z-depth-1-half map-container"
            style={{ height: "400px" }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2847.156386274549!2d26.10943757413398!3d44.47096708559263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1f991432060b1%3A0x7d830a8e7b99e841!2sE-P%20RAIL%20SRL!5e0!3m2!1sro!2sro!4v1599999027192!5m2!1sro!2sro"
              title="This is a unique title"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
            />
          </div>
          <br />
          <MDBRow className="text-center">
            <MDBCol md="4">
              <Link tag="a" floating  className="epCardButton">
                <MDBIcon icon="map-marker-alt" />
              </Link>
              <p>241 A Bd.Barbu Vacarescu, 3rd floor, District 2</p>
              <p className="mb-md-0">Bucharest, Romania.</p>
            </MDBCol>
            <MDBCol md="4">
              <Link tag="a" floating   className="epCardButton">
                <MDBIcon icon="phone" />
              </Link>
              <p>+40 31 420 40 28 </p>
              <p className="mb-md-0">Mon - Fri, 8:00-22:00</p>
            </MDBCol>
            <MDBCol md="4">
              <Link tag="a" floating className="epCardButton">
                <MDBIcon icon="envelope" />
              </Link>
              <p>office@e-prail.ro </p>
              <p className="mb-md-0">salee@e-prail.ro</p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    </motion.div>
  );
}

export default ContactPage;