import React from "react";
import {Link} from 'react-router-dom';
import { motion } from "framer-motion";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon
} from "mdbreact";

import sectionImage1 from "../../assets/images/about1.jpg";
import sectionImage2 from "../../assets/images/about2.jpg";
import "./AboutUs.css";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t, i18n } = useTranslation();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="epSection">
        <h1 className="epPageTitle">{t("About.pageTitle")} <span className="epSpantitle">{t("About.pageSpan")}</span></h1>
        <img className="epImgSection img-fluid" src={sectionImage1} />
        <div className="divider"></div>
      </div>
      <div className="epSection epCard">
       
        <MDBContainer>
          <MDBRow className="epFloatTextBox">
            <MDBCol>
              <h3>{t("About.pcT")}</h3>
              <p>{t("About.p1")}</p>
              <p>{t("About.p2")}</p>
            </MDBCol>
          </MDBRow>
          <hr/>
          <MDBRow className="epFloatTextBox">
          <MDBCol>
              <h3>{t("About.missionT")}</h3>
              <ul>
                <li><MDBIcon icon="angle-right"/>{t("About.missionL1")}</li>
                <li><MDBIcon icon="angle-right"/>{t("About.missionL2")}</li>
                <li><MDBIcon icon="angle-right"/>{t("About.missionL3")}</li>
                
              </ul>
              
            </MDBCol>
            <MDBCol>
              <h3>{t("About.promiseT")}</h3>
              <ul>
                <li><MDBIcon icon="angle-right"/>{t("About.promiseL1")}</li>
                <li><MDBIcon icon="angle-right"/>{t("About.promiseL2")}</li>
                <li><MDBIcon icon="angle-right"/>{t("About.promiseL3")}</li>
                
              </ul>
            </MDBCol>
          </MDBRow>
          <MDBRow className="epAboutSecondRow">
          <MDBCol size="4" className="epColAboutSection">
      
                 <h4>{t("About.fleetT")}</h4>
               
                  <p>{t("About.fleetText")}</p>
                
                  {/* <Link className="epCardButton">{t("About.fleetBtn")}</Link> */}
               
             
          </MDBCol>
          <MDBCol size="4" className="epColAboutSection">
            
             
              <h4>{t("About.serviceT")}</h4>
              
               <p>{t("About.serviceText")}</p> 
             
                <Link to="/service" className="epCardButton">{t("About.serviceBtn")}</Link>
            
          </MDBCol>
          <MDBCol size="4" className="epColAboutSection">
            
            
              <h4>{t("About.teamT")}</h4>
               <p>{t("About.teamText")}</p>
               
                <Link to="/team" className="epCardButton">{t("About.teamBtn")}</Link>
           
          </MDBCol>
          </MDBRow>
        </MDBContainer>
        
   
      </div>

      <div className="epSection">
        <img className="epImgSection img-fluid" src={sectionImage2} />
      </div>
    </motion.div>
  );
};

export default AboutUs;
