const videos = {
    LEMA035:[
        {
            col:6,
            id:1,
            thumb:"https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709402/LEMA035/IMG_6572_xfu683.jpg",
            src:"https://www.youtube.com/embed/ZucwsSlYh48",
        },
        {
            col:6,
            id:2,
            src:"https://www.youtube.com/embed/proR8UwgQ4w"
        },
        {
            col:6,
            id:3,
            src:"https://www.youtube.com/embed/Sx5T8I5iY8o"
        },
        {
            col:6,
            id:4,
            src:"https://www.youtube.com/embed/A7KOP-GXYQo"
        }
    ],
    LEMA036:[
        {
            col:6,
            id:1,
            thumb:"https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709290/LEMA036/lema36014_zouqea.jpg",
            src:"https://www.youtube.com/embed/IQx4EwS6kqA",
        },
        {
            col:6,
            id:2,
            src:" https://www.youtube.com/embed/AcIocjXSQiM"
        },
        {
            col:6,
            id:3,
            src:"https://www.youtube.com/embed/UPlk67OozBs"
        },
        {
            col:6,
            id:4,
            src:"https://www.youtube.com/embed/ooWkzUWjbv8"
        }
    ],
    LEMA038:[
        {
            col:6,
            id:1,
            thumb: "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709204/LEMA038/lema038002_xtmkgz.jpg",
            src:"https://www.youtube.com/embed/knI9z2BDs1s",
        },
        {
            col:6,
            id:2,
            src:"https://www.youtube.com/embed/xg1V-enWqWE"
        },
        {
            col:6,
            id:3,
            src:"https://www.youtube.com/embed/TOroANauji8"
        },
        {
            col:6,
            id:4,
            src:"https://www.youtube.com/embed/VrOsGV3TgyM"
        }
    ],
    LEMA039:[
        {
            col:6,
            id:1,
            thumb:"https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709176/LEMA039/IMG_0365_dn2eqh.jpg",
            src:"https://www.youtube.com/embed/gii0kagKh5s",
        },
        {
            col:6,
            id:2,
            src:"https://www.youtube.com/embed/OjdzGk0TfmE"
        },
        {
            col:6,
            id:3,
            src:"https://www.youtube.com/embed/hRR8oVsZQvk"
        },
        {
            col:6,
            id:4,
            src:"https://www.youtube.com/embed/L65CcJu3BVc"
        }
    ],
    LEMA048:[
        {
            col:6,
            id:1,
            thumb:"https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709432/LEMA048/lema048015_dthrse.jpg",
            src:"https://www.youtube.com/embed/DsGn8scc8Lg",
        },
        {
            col:6,
            id:2,
            src:"https://www.youtube.com/embed/Hm0-bBYiStI"
        },
        {
            col:6,
            id:3,
            src:"https://www.youtube.com/embed/fzNJKl9-5jw"
        },
        {
            col:6,
            id:4,
            src:"https://www.youtube.com/embed/nrxF2-VeOrg"
        }
    ],
    s004:[
        {
            col:6,
            id:1,
            thumb:"https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710617/S004/IMG_9082_imbl7a.jpg",
            src:"https://www.youtube.com/embed/cDMdzcm345I",
        },
        {
            col:6,
            id:2,
            src:"https://www.youtube.com/embed/euc6Z7mG3oQ"
        },
        {
            col:6,
            id:3,
            src:"https://www.youtube.com/embed/ne3GsYMdy_4"
        },
        {
            col:6,
            id:4,
            src:"https://www.youtube.com/embed/bNyFOpSrNHg"
        }
    ],
    s005:[
        {
            col:6,
            id:1,
            thumb:"https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710573/S005/Smartron005020_huuwhj.jpg",
            src:"https://www.youtube.com/embed/vrsdWx_ha-w",
        },
        {
            col:6,
            id:2,
            src:"https://www.youtube.com/embed/oAnu1oHST_Q"
        },
        {
            col:6,
            id:3,
            src:"https://www.youtube.com/embed/O5FECeve504"
        },
        {
            col:6,
            id:4,
            src:"https://www.youtube.com/embed/pp5Ag2za89s"
        }
    ],
    s006:[
        {
            col:6,
            id:1,
            thumb: "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710603/S006/ST006015_pklk1i.jpg",
            src:"https://www.youtube.com/embed/HUbsYXDVip8",
        },
        {
            col:6,
            id:2,
            src:"https://www.youtube.com/embed/pZ6Z_CsQv78"
        },
        {
            col:6,
            id:3,
            src:"https://www.youtube.com/embed/pDbFrrTsnTo"
        },
        {
            col:6,
            id:4,
            src:"https://www.youtube.com/embed/Jq_XMjr9RlU"
        }
    ],
    LEMA037:[
        {
            col:6,
            id:1,
            thumb: "https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603709233/LEMA037/IMG_5896_uoef6l.jpg",
            src:"https://www.youtube.com/embed/R7JmjKqnHus",
        },
        {
            col:6,
            id:2,
            src:"https://www.youtube.com/embed/mzqI0ykczDg"
        },
        {
            col:6,
            id:3,
            src:"https://www.youtube.com/embed/QJ7k04jnKRw"
        },
        {
            col:6,
            id:4,
            src:"https://www.youtube.com/embed/No2GFsYN1RY"
        }
    ]
}

export default videos;