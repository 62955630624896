import React, { useState, useContext, useRef } from "react";
import {Link} from "react-router-dom"
import styled from "styled-components";


import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBDropdownMenu
} from "mdbreact";
//Components
import Burger from "./Burger";
//assets
import { ReactComponent as MenuLogo } from "../../../assets/images/logo.svg";

const EpNav = styled.nav`
  width: 100%;
  height: 80px;
  border-bottom: 2px solid #f1f1f1;
  padding: 55px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const language = ["Ro", "En"];

const Menu = ({ translationHandle }) => {
  const [currentLang, setCurrentLang] = useState("En");
  const dropDownHandller = () => {
    if (currentLang == language[0]) {
      return (
        <MDBDropdownItem
          onClick={() => {
            setCurrentLang(language[1]);
            translationHandle("en"); //daca merge contextul stergem lini asta
          }}
        >
          {language[1]}
        </MDBDropdownItem>
      );
    } else {
      return (
        <MDBDropdownItem
          onClick={() => {
            setCurrentLang(language[0]);
            translationHandle("ro"); //daca merge contextul stergem lini asta
          }}
        >
          {language[0]}
        </MDBDropdownItem>
      );
    }
  };
  return (
    <EpNav>
      <div className="logo">
        <Link to="/"><MenuLogo className="img-responsive"/></Link> 
      </div>
     
      <Burger />
      <MDBDropdown className="epTransDrop">
        <MDBDropdownToggle caret className="epBtnLg">
          {currentLang}
        </MDBDropdownToggle>
        <MDBDropdownMenu className="epBtnLgDrop">{dropDownHandller()}</MDBDropdownMenu>
      </MDBDropdown>
   </EpNav>
  );
};

export default Menu;


