import React from "react";
import { MDBCol, MDBCard, MDBCardImage, MDBCardBody } from "mdbreact";
import {Link} from "react-router-dom";

import "./Card.css";

const Card =( props) => {
  return (
    <MDBCol size="auto" md="4">
      <MDBCard  className="epCardPoze">
        <MDBCardImage
          className="img-fluid epCardImgPoze"
          src={props.thumb}
          waves
        />
        <MDBCardBody className="epCardBody">
        <Link className="epCardBtn"  to={`/train_photos/${props.name}`}>{props.name}</Link>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  );
};

export default Card;
