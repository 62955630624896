import React from "react";
import { useParams } from "react-router-dom";

import videosWagons from '../videoWagons';
import {
 
  MDBCol,
  MDBRow,
  MDBContainer,

} from "mdbreact";

const WagonsVideoPage = () => {
  let { id } = useParams();
  const test = () => {
    const items = videosWagons[id];
    return (
      
        <MDBContainer>
            <MDBRow>
          
          <h1 className="epPageTitle1 text-center my-5">{id}</h1>
        </MDBRow>
          <MDBRow className="mb-5" center>
            {items.map(i => {
              return (
                <MDBCol md={i.col} size="auto">
                 <iframe className="epvideo"  src={i.src} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </MDBCol>
              );
            })}
          </MDBRow>

        </MDBContainer>

    );
  };
  return <>{test()}</>;
};

export default WagonsVideoPage;
