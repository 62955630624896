const videosWagons = {
    Cisterne : [
        {
            col:6,
            id:1,
            thumb:"https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710637/CIST/IMG_7546_ifiatg.jpg",
            src:"https://www.youtube.com/embed/Sn2-fWklxX8",
        },
        {
            col:6,
            id:2,
            src:"https://www.youtube.com/embed/jkuQbeOn1d0"
        },
        {
            col:6,
            id:3,
            src:"https://www.youtube.com/embed/EkXyvtOJt9Y"
        },
        {
            col:6,
            id:4,
            src:"https://www.youtube.com/embed/tCuDBYqNRDw"
        }
    ],
    Eacs:[
        {
            col:6,
            id:1,
            thumb:"https://res.cloudinary.com/dzhgwqzkp/image/upload/v1603710671/EACS/eacs016_yc3pdd.jpg",
            src:"https://www.youtube.com/embed/7_TtOzif160",
        },
        {
            col:6,
            id:2,
            src:"https://www.youtube.com/embed/Zqm3jL1Jp60"
        },
        {
            col:6,
            id:3,
            src:"https://www.youtube.com/embed/6h4G-f7yy1E"
        },
        {
            col:6,
            id:4,
            src:"https://www.youtube.com/embed/2AW7VOkXCbo"
        }
    ],
    HA:[
        {
            col:6,
            id:1,
            thumb:"https://res.cloudinary.com/dzhgwqzkp/image/upload/v1610610952/HA/HA_0003_ha010-min_w4xtut.png",
            src:"https://www.youtube.com/embed/4_Na1hQxO6Y",
        },
        {
            col:6,
            id:2,
            src:"https://www.youtube.com/embed/8N-6ZlvTFWM"
        },
        {
            col:6,
            id:3,
            src:"https://www.youtube.com/embed/enUWeur_Sqs"
        },
        {
            col:6,
            id:4,
            src:"https://www.youtube.com/embed/1qxEGN_lS48"
        }
    ]
}

export default videosWagons