import React from "react";
import { Link } from "react-router-dom";
//Components
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { ScrollingProvider, SectionLink, Section } from "react-scroll-section";
import { motion } from "framer-motion";
//Translate
import { useTranslation } from "react-i18next";
//CSS
import "./Home.css";
import "./button.css";

//SVG
import { ReactComponent as ScrollDownSVG } from "./scrolldown.svg";
import { ReactComponent as MainLogo } from "./mainLogo.svg";

//import cookies
import CookieConsent from "react-cookie-consent";


const Home = () => {
  const { t, i18n } = useTranslation();
  let video = "https://res.cloudinary.com/dzhgwqzkp/video/upload/v1608707009/video/cliphome_1_1_rskqh4.mp4"
  let video1 = "https://res.cloudinary.com/dzhgwqzkp/video/upload/v1607962672/video/jskdba_nhqir5.mp4"
  let video2= "https://res.cloudinary.com/dzhgwqzkp/video/upload/v1607962790/video/sdffsd_ap6aci.mp4"
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ScrollingProvider scrollBehavior="smooth">
        <MDBContainer fluid className="videoBackground">
          <div className="epLogoAnimation"><MainLogo /></div>
          <div className="overlay"></div>
          <video
            autoPlay={true}
            muted={true}
            loop={true}
            id="myVideo"
            width="100"
          >
            <source src={video} type="video/mp4" />
            <source src={video} type="video/ogg" />
          </video>
          <div className="scrollButton">
            <SectionLink section="cards">
              {({ onClick, isSelected }) => (
                <ScrollDownSVG onClick={onClick} selected={isSelected} />
              )}
            </SectionLink>
          </div>
        </MDBContainer>

        <Section id="cards">
          <MDBContainer fluid className="cardsSection">
            <MDBRow className="cardsRow">
              <MDBCol size="6" className="card_1 cardBg_1 cardBg">
                <div className="case_text_container">
                  <div className="text_mask">
                    <h2 className="case_text_name">{t("Home.Card_1_Title")}</h2>
                  </div>
                  <div className="text_mask">
                    <p className="case_text_description">
                      {t("Home.Card_1_BodyText")}
                    </p>
                  </div>
                </div>

                <div className="videoBg">
                  <video autoPlay={true} muted={true} loop={true} id="myVideo1">
                    <source src={video1} type="video/mp4" />
                    <source src={video1} type="video/ogg" />
                  </video>
                </div>

                <div className="case_button">
                  <Link to="/train_photos">
                    <button className="ctrl-standard typ-subhed fx-bubbleDown">
                      {t("Home.PicBtn")}
                    </button>
                  </Link>
                  <Link to="/train_videos">
                    <button className="ctrl-standard typ-subhed fx-bubbleUp">
                      {t("Home.VideoBtn")}
                    </button>
                  </Link>
                </div>
              </MDBCol>

              <MDBCol size="6" className="card_1 cardBg_2 cardBg">
                <div className="case_text_container">
                  <div className="text_mask">
                    <h2 className="case_text_name">{t("Home.Card_2_Title")}</h2>
                  </div>
                  <div className="text_mask">
                    <p className="case_text_description">
                      {t("Home.Card_2_BodyText")}
                    </p>
                  </div>
                </div>
                <div className="videoBg">
                  <video autoPlay={true} muted={true} loop={true} id="myVideo1">
                    <source src={video2} type="video/mp4" />
                    <source src={video2} type="video/ogg" />
                  </video>
                </div>
                <div className="case_button">
                  <Link to="/wagons_photos">
                    <button className="ctrl-standard typ-subhed fx-bubbleDown">
                      {t("Home.PicBtn")}
                    </button>
                  </Link>
                  <Link to="/wagons_videos">
                    <button className="ctrl-standard typ-subhed fx-bubbleUp">
                      {t("Home.VideoBtn")}
                    </button>
                  </Link>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </Section>
      </ScrollingProvider>
      <CookieConsent
        buttonText= {t("Home.CookeiBtn")}
        enableDeclineButton
        declineButtonText= {t("Home.CookieDeclineBtn")}>
        {t("Home.CookieText")}  
      </CookieConsent>
    </motion.div>
  );
};

export default Home;
